<template>
  <Dialog v-model="_isOpen" type="side">
    <AcCalculator v-if="_isOpen" :ac-horse-power="acHorsePower" @compatible="compatible" />
  </Dialog>
</template>
<script setup lang="tsx">
const props = withDefaults(
  defineProps<{
    modelValue?: boolean;
    acHorsePower?: number | undefined;
  }>(),
  {
    modelValue: false,
    acHorsePower: undefined,
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
  (e: 'compatible', hp: number): void;
}>();

const AcCalculator = defineAsyncComponent(() => import('../AcCalculatorFields.vue'));

const _isOpen = ref(props.modelValue);

const compatible = (hp: number) => {
  emit('compatible', hp);
};

watch(
  () => props.modelValue,
  value => {
    _isOpen.value = value;
  },
);

watch(
  () => _isOpen.value,
  value => {
    emit('update:modelValue', value);
  },
);
</script>
